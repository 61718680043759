import React from 'react'
import PropTypes from 'prop-types'

const Button = (props) => {
  return (
    <button
      className={`Button ${props.secondary && "Button--secondary"} ${props.fullWidth && "full-width"} ${props.isDisabled && "isDisabled"} ${props.classes}`}
      onClick={props.isDisabled ? null : props.onPress}>
      {props.children}
    </button>
  )
}

Button.propTypes = {
  classes: PropTypes.string,
  fullWidth: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  secondary: PropTypes.bool
}

export default Button
