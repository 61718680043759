import React, {useState} from 'react'
import ReactMapboxGl, { Layer, Feature, ZoomControl } from 'react-mapbox-gl'
const MAPBOX_API_TOKEN = "pk.eyJ1Ijoid2VhcmVmbG9yZW5zIiwiYSI6ImNrM3Bjc212bjAxdWszb3JnYno0bHR3emgifQ.RNL6IF9qADXqU2i2MqRZ1A"

const MapboxMap = ReactMapboxGl({ accessToken: MAPBOX_API_TOKEN })

class Map extends React.Component {
  constructor(props){
    super(props)
    this.onMarkerPressed = this.onMarkerPressed.bind(this)
  }

  render() {
    let { center, locations, selectedLocation, zoom } = this.props

    return (
      <MapboxMap
        style="mapbox://styles/weareflorens/ck546ugtw166h1crz9q91iwp8"
        center={center}
        zoom={zoom}
        flyToOptions={{
          speed: 2.5, // make the flying slow
          curve: 1.5, // change the speed at which it zooms out
        }}
      >
        <Layer type="symbol" id="marker" layout={{ "icon-image": "iconmonstr-location-5"}}>
          { locations.map(loc => (
            <Feature
              coordinates={[loc.longitude, loc.latitude]}
              key={loc.id}
              onClick={() => this.onMarkerPressed(loc)}
              onMouseEnter={(mapWithEvt) => mapWithEvt.map.getCanvas().style.cursor = "pointer"}
              onMouseLeave={(mapWithEvt) => mapWithEvt.map.getCanvas().style.cursor = ""}
            />
          ))}
        </Layer>
        <ZoomControl />
      </MapboxMap>
    )
  }

  onMarkerPressed(location){
    console.log("Marker pressed: ", location)
    this.setState({ selectedLocation: location })
    this.props.onLocationSelected(location)
  }
}

export default Map
