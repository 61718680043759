import React from 'react'
import PropTypes from 'prop-types'
import api from 'utils/api'
import Notice from 'components/atoms/Notice'
import TopNav from 'components/molecules/TopNav'

class WaitlistPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      email: "",
      errorMessage: null,
      isAlreadyExistsVisible: false,
      isConfirmationVisible: false,
      isLoading: false,
      waitlistPosition: null
    }

    this.submitEmail = this.submitEmail.bind(this)
  }

  render(){
    return (
      <main className="Page WaitlistPage">
        <TopNav />

        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <section className={`WaitlistPage__hero ${this.state.isConfirmationVisible && '--joined'}`}></section>
            </div>

            <div className="col-xs-12 col-sm-6">
              <section className="HalfPage WaitlistPage__content">
                { !this.state.isConfirmationVisible &&
                  <div className="WaitlistPage__form">
                    <div className="container-fluid">
                      <div className="row center-xs">
                        <div className="col-xs-10">
                          <h3 className="HomeSectionTitle text-left">Join the waitlist</h3>
                        </div>
                      </div>

                      <div className="row center-xs mb-4">
                        <div className="col-xs-10 font-sm">
                          <p class="text-left">
                            We’re working hard to get you an amazing at-home sauna
                            experience as soon as we can. We’ll fulfill orders first
                            come first serve, with waitlist folks getting priority.
                          </p>
                          <p class="text-left">
                            We’d also love to get your take, help nudge it
                            towards something more personal for you.
                          </p>
                        </div>
                      </div>


                      <div className="row center-xs">
                        <div className="col-xs-10">
                          <label className="TextField">
                            <span className="TextField__label center-xs start-sm">
                              What’s your email address?
                            </span>
                            <input
                              autoFocus
                              type="email"
                              className="TextInput"
                              placeholder="eg. hi@florenshealth.com"
                              onChange={(e) => this.setState({ email: e.target.value })}
                              onKeyDown={(e) => { if (e.key === 'Enter'){ this.submitEmail() }}}
                              value={this.state.email}
                            />
                          </label>
                        </div>
                      </div>

                      { this.state.errorMessage &&
                        <div className="row center-xs">
                          <div className="col-xs-10">
                            <Notice text={this.state.errorMessage} />
                          </div>
                        </div>
                      }

                      <div className="row center-xs">
                        <div className="col-xs-6">
                          <button
                            className={`Button full-width ${this.state.isLoading && "isDisabled"}`}
                            disabled={this.state.isLoading}
                            onClick={this.submitEmail}
                          >
                            { this.state.isLoading ? "Loading..." : "Continue" }
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                }

                { this.state.isConfirmationVisible &&
                  <div className="WaitlistPage__confirmation">
                    <div className="container-fluid">
                      <div className="row center-xs">
                        <div className="col-xs-10">
                          <h3 className="HomeSectionTitle text-left">Welcome to Florens 🎉</h3>
                        </div>
                      </div>

                      <div className="row center-xs">
                        <div className="col-xs-10">
                          <div className="WaitlistPage__confirmation--content">
                            <p className="text-left">
                              We’ve {this.state.isAlreadyExistsVisible && "(already) "}got you down, you’re <b>#{this.state.waitlistPosition}</b> on the list!
                            </p>
                            <p className="text-left">
                              You can come back anytime to check your status.
                            </p>
                            <p className="text-left">
                              Next: we’d love to invite you to join other sauna enthusiasts (and wellbeing seekers) <a href="https://discord.gg/krhcxZNAyJ">in our Discord community</a>.
                            </p>
                            <p className="text-left">
                              Share what matters to you, stay updated on our progress, learn more about sauna (and other refresh practices), and connect w/ others on the wellbecoming path.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row center-xs">
                        <div className="col-xs-9">
                          <a href="https://discord.gg/krhcxZNAyJ" className="Button full-width" target="_blank">
                            Join the conversation
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </section>
            </div>
          </div>
        </div>
      </main>
    )
  }

  submitEmail(){
    const { email } = this.state
    console.log("Submitting email: ", email)
    this.setState({ isLoading: true })
    if (email.length == 0) {
      this.setState({ errorMessage: "Please enter an email.", isLoading: false })
      return
    }

    api.submitWaitlistEmail(email)
    .then(res => {
      console.log("Response: ", res)
      this.setState({
        isConfirmationVisible: true,
        isAlreadyExistsVisible: res.data.already_exists,
        waitlistPosition: res.data.user.id,
        isLoading: false
      })
    })
    .catch(error => {
      console.error("Error: ", error.response.data)
      this.setState({ errorMessage: error.response.data.message, isLoading: false })
    })
  }

}

WaitlistPage.propTypes = {}

export default WaitlistPage
