// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import React from 'react';
import ReactDOM from 'react-dom';
import RefreshMapApp from 'components/RefreshMapApp';
import AdminApp from 'components/AdminApp';
import WaitlistPage from 'components/pages/WaitlistPage';

document.addEventListener('DOMContentLoaded', () => {
  prepRefreshMapAppPage()
  prepAdminPage()
  prepWaitlistPage()
});

function prepRefreshMapAppPage() {
  const mount = document.getElementById('RefreshMapApp')
  if (mount) {
    ReactDOM.render(<RefreshMapApp />, mount);
  }
}

function prepAdminPage() {
  const adminMount = document.getElementById('AdminApp')
  if (adminMount) {
    ReactDOM.render(<AdminApp />, adminMount);
  }
}

function prepWaitlistPage() {
  const mount = document.getElementById('WaitlistPage')
  if (mount) {
    ReactDOM.render(<WaitlistPage />, mount);
  }
}
