import React from 'react'
import PropTypes from 'prop-types'

export default function TopBanner(props) {
  return (
    <aside className="TopBanner">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-xs-12">
            <p className="bg-color-wt-sand color-lt-sand mb-0 p-1 font-xs font-meta text-center">
              { props.children }
            </p>
          </div>
        </div>
      </div>
    </aside>
  )
}


TopBanner.propTypes = {}
