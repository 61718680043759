import React from 'react'
import PropTypes from 'prop-types'

export default function TopNav(props) {
  let { selected } = props;

  return (
    <nav className="TopNav --light">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-sm-5 col-md-6">
            <div className="d-flex center-xs start-sm" style={{height: "100%"}}>
              <a href="/" className="TopNav__logo"></a>
            </div>
          </div>
          <div className="col-xs-12 col-sm-7 col-md-6 text-right">
            <ul className="center-xs end-sm">
              <li><a href="/">Sauna</a></li>
              <li><a href="/library">Learn</a></li>
              <li><a href="/map">Map</a></li>
              <li><a href="https://weareflorens.notion.site/Brand-Riff-v1-1-ae8189430b8a475aa721b05010cb365b" target="_blank">About</a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}


TopNav.propTypes = {}
