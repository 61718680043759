import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import api from 'utils/api'
import Button from 'components/atoms/Button'
import AdminTopNav from 'components/organisms/AdminTopNav'
import TextField from 'components/molecules/TextField'

export default function AdminUserEditPage(props) {
  let history = useHistory()
  let { userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    api.admin.editUser(userId)
    .then(res => {
      console.log("Got response: ", res.data)
      setUser(res.data.user)
    })
    .catch(error => console.error("Error: ", error))
  }, [])

  console.log("User is: ", user)

  return (
    <main className="AdminApp AdminUserEditPage">
      <AdminTopNav />
      <div className="container-fluid">
        <div className="row center-xs text-left">
          <div className="col-12 col-md-6">
            { user !== null &&
              <div className="AdminUserEditForm">
                <TextField
                  text="Email"
                  value={user.email}
                  onChange={(e) => setUser({...user, email: e.target.value}) }
                />

                <TextField
                  text="Waitlist Join Date"
                  value={user.joined_waitlist_on}
                  onChange={(e) => setUser({...user, joined_waitlist_on: e.target.value}) }
                />

                <Button
                  fullWidth
                  onPress={() => {
                    api.admin.updateUser(user)
                    .then(res    => {
                      console.log("Response: ", res)
                      history.push('/admin/users')
                    })
                    .catch(error => console.error("Error: ", error))
                  }}
                >
                  Update User
                </Button>
              </div>
            }
          </div>
        </div>
      </div>
    </main>
  )
}
