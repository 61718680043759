import React from 'react'
import moment from 'moment'
import { Link, withRouter } from 'react-router-dom'
import api from 'utils/api'
import Button from 'components/atoms/Button'
import AdminTopNav from 'components/organisms/AdminTopNav'

class AdminUsersPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      users: []
    }
  }

  componentDidMount(){
    api.admin.getUsers()
    .then(res => {
      console.log("Response: ", res)
      this.setState({ users: res.data.users })
    })
    .catch(error => console.error("Error: ", error))
  }

  render() {
    const { users } = this.state

    return (
      <main className="AdminUsersPage">
        <AdminTopNav selected="users" />

        <div className="container-fluid pt-4">
          <div className="row">
            <div className="col-xs-12">
              <header className="AdminUserTableHeader mb-2">
                <div className="row">
                  <div className="col-xs-5">
                    <b>👋 People ({users.length})</b>
                  </div>

                  <div className="col-xs-3">
                    <b>🗓 Created</b>
                  </div>

                  <div className="col-xs-3">
                    <b>📋 Waitlist Joined</b>
                  </div>

                  <div className="col-xs text-right">
                    <b>Actions</b>
                  </div>
                </div>
              </header>


              { users.map(user =>
                <div className="AdminUserRow p-2 mb-2">
                  <div className="row middle-xs">
                    <div className="col-xs-5">
                      <div className="AdminUserRow__email">
                        <img src={`https://www.gravatar.com/avatar/${user.gravatar_url}`} className="AdminUserRow__avatar" />
                        <span>{user.email}</span>
                      </div>
                    </div>
                    <div className="col-xs-3">
                      <span>{ moment(user.created_at).format("MM/DD/YY") }</span>
                    </div>
                    <div className="col-xs-3">
                      { user.joined_waitlist_on &&
                        <span>{moment(user.joined_waitlist_on).format("MM/DD/YY")}</span>
                      }
                    </div>
                    <div className="col-xs text-right">
                      <Link to={`/admin/users/${user.id}/edit`}>Edit</Link>
                    </div>
                  </div>
                </div>

              )}
            </div>
          </div>
        </div>

      </main>
    )
  }
}

export default withRouter(AdminUsersPage)
