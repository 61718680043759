import React from 'react'
import api from 'utils/api'
import LocationCard from 'components/molecules/LocationCard'
import Map from 'components/organisms/Map'
import TopBanner from 'components/molecules/TopBanner'
import TopNav from 'components/molecules/TopNav'
import TextInput from 'components/atoms/TextInput'

class RefreshMapApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      locations: [],
      selectedLocation: null,
      searchQuery: null,
      map: {
        center: { lat: 37.441207, lon: -122.158523593296 },
        zoom: [11]
      }
    }

    this.handleLocationSelected = this.handleLocationSelected.bind(this)
    this.handleSearchPressed = this.handleSearchPressed.bind(this)
  }

  componentDidMount(){
    this.setState({ isLoading: true })
    api.getLocations()
    .then(res => this.receiveInitialData(res.data))
    .catch(error => console.error("Error: ", error))
  }

  render(){
    let { isLoading, locations, searchQuery, selectedLocation } = this.state

    return(
      <div className="RefreshMapApp bg-color-lt-sand">
        <TopNav />

        <TopBanner>
          Got a spot you'd like to share? <a href="https://discord.gg/krhcxZNAyJ" target="_blank" className="color-lt-sand font-xs font-meta">Join the conversation here</a>.
        </TopBanner>

        <main>
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-8">
                <div className="RefreshMapContent">
                  <div className="row center-xs">
                    <div className="col-xs-11">
                      <TextInput
                        onChange={(e) => this.setState({ searchQuery: e.target.value })}
                        onEnterPressed={this.handleSearchPressed}
                        value={this.state.searchQuery}
                        placeholder={'Search by city + state, or zip'}
                        type="search"
                      />
                    </div>
                  </div>

                  { isLoading &&
                    <div className="row">
                      <div className="col-xs-11 center-xs text-center">
                        <p className="font-meta color-wt-sand font-sm font-500 text-uppercase pt-4">Loading...</p>
                      </div>
                    </div>
                  }

                  { !isLoading && locations.length !== 0 &&
                    <div className="row">
                      <div className="col-xs-12">
                        <ul className="LocationsList">
                          { locations.map(loc => {
                            return (
                              <li className="LocationListItem" key={loc.name} onClick={() => this.handleLocationSelected(loc)}>
                                <LocationCard
                                  location={loc}
                                  isSelected={selectedLocation && selectedLocation.id === loc.id}
                                />
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  }


                  { !isLoading && locations.length == 0 &&
                    <div className="row">
                      <div className="col-xs-11 center-xs text-center">
                        <p className="font-meta color-wt-sand font-sm font-500 text-uppercase pt-4">
                          No refresh spaces found near {searchQuery}
                        </p>
                      </div>
                    </div>
                  }

                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <Map
                  center={this.state.map.center}
                  locations={this.state.locations}
                  onLocationSelected={this.handleLocationSelected}
                  selectedLocation={this.state.selectedLocation}
                  zoom={this.state.map.zoom}
                />
              </div>
            </div>
          </div>
        </main>
      </div>

    )
  }

  receiveInitialData(initialData) {
    const locations = initialData.locations
    console.log("Received locations: ", locations)
    this.setState({ locations, isLoading: false })

    this.handleLocationSelected(locations[0])
  }

  handleLocationSelected(location){
    this.setState({
      map: {
        center: { lat: location.latitude, lon: location.longitude },
        zoom: [12]
      },
      selectedLocation: location
    })
  }

  handleSearchPressed(){
    this.setState({ isLoading: true })
    api.getLocations(this.state.searchQuery)
    .then(res => this.receiveInitialData(res.data))
    .catch(error => console.error("Error: ", error))
  }
}

export default RefreshMapApp
