import React from 'react'
import PropTypes from 'prop-types'

export default function AdminTopNav(props) {
  let { selected } = props;

  return (
    <nav className="AdminTopNav">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-4">
            <a href="/" className="AdminTopNav__logo"></a>
          </div>
          <div className="col-xs-8 d-flex end-xs">
            <ul className="AdminTopNav__list">
              <li>
                <a href="/admin/users" className={`${selected == "users" && "isSelected"}`}>
                  Users
                </a>
              </li>
              <li>
                <a href="/admin/locations" className={`${selected == "locations" && "isSelected"}`}>
                  Locations
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}


AdminTopNav.propTypes = {
  selected: PropTypes.string
}
