import React from 'react'
import PropTypes from 'prop-types'

const Notice = (props) => {
  return (
    <aside className="Notice" {...props}>
      <div className="row text-center">
        <div className="col-xs-12">
          <span>{props.text}</span>
        </div>
      </div>
    </aside>
  )
}

Notice.propTypes = {
  text: PropTypes.string.isRequired
}

export default Notice
