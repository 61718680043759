import React from 'react'
import PropTypes from 'prop-types'

export default function AdminSubNav(props) {
  let { selected } = props;

  return (
    <aside className="bg-color-wt-sand pt-1 pb-1">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 end-xs">
            { props.children }
          </div>
        </div>
      </div>
    </aside>
  )
}


AdminSubNav.propTypes = {}
