import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import AdminUsersPage from 'components/pages/AdminUsersPage'
import AdminUserEditPage from 'components/pages/AdminUserEditPage'
import AdminLocationsList from 'components/pages/AdminLocationsList'
import AdminNewLocation from 'components/pages/AdminNewLocation'
import AdminEditLocation from 'components/pages/AdminEditLocation'


class AdminApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bannerNotice: null
    }
  }

  render() {
    return (
      <main className="AdminApp" className="bg-color-lt-sand pb-3">
        <Router>
          <Switch>
            <Route path="/admin/locations/new">
              <AdminNewLocation />
            </Route>
            <Route path="/admin/locations/:locationId/edit">
              <AdminEditLocation />
            </Route>
            <Route path="/admin/locations">
              <AdminLocationsList />
            </Route>
            <Route path="/admin/users/:userId/edit">
              <AdminUserEditPage />
            </Route>
            <Route path="/admin/">
              <AdminUsersPage />
            </Route>
          </Switch>
        </Router>
      </main>
    )
  }
}

export default AdminApp
