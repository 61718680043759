import React from 'react'
import PropTypes from 'prop-types'
import TextInput from 'components/atoms/TextInput'

const TextField = (props) => {
  const classes = `TextField ${props.isValid && "isValid"} ${props.classes}`

  return (
    <label className={classes}>
      <span className="TextField__label center-xs start-sm">
        {props.text}
      </span>
      <TextInput
        autoFocus={props.autoFocus}
        type={props.type}
        className="TextInput"
        placeholder={props.placeholder}
        onChange={props.onChange}
        onKeyDown={(e) => { if (e.key === 'Enter'){ props.onEnterPressed() }}}
        value={props.value}
      />
    </label>
  )
}

TextField.propTypes = {
  autoFocus: PropTypes.bool,
  classes: PropTypes.string,
  onChange: PropTypes.func,
  onEnterPressed: PropTypes.func,
  placeholder: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf(["text", "email"]),
  value: PropTypes.string.isRequired
}

export default TextField
