import React from 'react'
import PropTypes from 'prop-types'
import { Link, Redirect, withRouter } from 'react-router-dom'
import api from 'utils/api'

import AdminSubNav from 'components/organisms/AdminSubNav'
import AdminTopNav from 'components/organisms/AdminTopNav'
import Button from 'components/atoms/Button'
import Notice from 'components/atoms/Notice'
import TextField from 'components/molecules/TextField'

class AdminEditLocation extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      location: {
        id: null,
        name: null,
        category: null,
        streetAddress: null,
        city: null,
        state: null,
        zip: null,
        latitude: null,
        longitude: null,
        photoUrl: null,
        webUrl: null,
        instagramUrl: null
      },
      formWasSuccessful: null,
      isLoading: false
    }

    this.handleFormSubmitted = this.handleFormSubmitted.bind(this)
    this.handleDeletePressed = this.handleDeletePressed.bind(this)
  }

  componentDidMount() {
    const { locationId } = this.props.match.params

    this.setState({
      isLoading: true,
      location: { id: locationId, ...this.state.location }
    })

    this.fetchData()
  }

  render() {
    const { location }   = this.state
    const { locationId } = this.props.match.params

    return (
      <main>
        <AdminTopNav />
        <AdminSubNav>
          <Link to={`/admin/locations`} className="font-meta font-xs">Cancel</Link>
        </AdminSubNav>

        <div className="container-fluid pt-3">
          { this.state.formWasSuccessful && <Redirect to="/admin/locations" /> }

          <main className="row around-xs">
            <div className="col-xs-12 col-md-6">
              { this.state.location.name && this.renderFields() }

              <div className="row between-xs">
                <div className="col-xs-3">
                  <Button
                    fullWidth
                    secondary
                    onPress={this.handleDeletePressed}
                    isDisbled={this.state.isLoading}
                  >
                    Delete
                  </Button>
                </div>
                <div className="col-xs-9">
                  <Button
                    fullWidth
                    onPress={this.handleFormSubmitted}
                    isDisbled={this.state.isLoading}
                  >
                    Update
                  </Button>
                </div>
              </div>

            </div>
          </main>
        </div>
      </main>

    )
  }

  renderFields() {
    return (
      <div className="FormFields">
        <div className="row">
          <div className="col-xs-12">
            <TextField
              autoFocus
              text="Name"
              id="name"
              placeholder={"e.g. Watercourse Way"}
              onChange={(e) => this.setState({ location: {...this.state.location, name: e.target.value} })}
              value={this.state.location.name}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <TextField
              text="Category"
              id="category"
              placeholder={"e.g. Hip, Charming, Luxe, Outdoor, Solid, Basic"}
              onChange={(e) => this.setState({ location: {...this.state.location, category: e.target.value} })}
              value={this.state.location.category}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <TextField
              text="Street Address"
              id="streetAddress"
              onChange={(e) => this.setState({ location: {...this.state.location, streetAddress: e.target.value} })}
              value={this.state.location.streetAddress}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-6">
            <TextField
              text="City"
              id="city"
              onChange={(e) => this.setState({ location: {...this.state.location, city: e.target.value} })}
              value={this.state.location.city}
            />
          </div>
          <div className="col-xs-2">
            <TextField
              text="State"
              id="state"
              onChange={(e) => this.setState({ location: {...this.state.location, state: e.target.value} })}
              value={this.state.location.state}
            />
          </div>
          <div className="col-xs-4">
            <TextField
              text="Zip"
              id="zip"
              onChange={(e) => this.setState({ location: {...this.state.location, zip: e.target.value} })}
              value={this.state.location.zip}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-6">
            <TextField
              text="Latitude"
              id="latitude"
              onChange={(e) => this.setState({ location: {...this.state.location, latitude: e.target.value} })}
              value={this.state.location.latitude}
            />
          </div>
          <div className="col-xs-6">
            <TextField
              text="Longitude"
              id="longitude"
              onChange={(e) => this.setState({ location: {...this.state.location, longitude: e.target.value} })}
              value={this.state.location.longitude}
            />
          </div>
        </div>


        <div className="row">
          <div className="col-xs-12">
            <TextField
              text="Photo URL"
              id="photoUrl"
              placeholder={"e.g. https://imgur.com/fj3a.jpg (400x300, use imgur direct link)"}
              onChange={(e) => this.setState({ location: {...this.state.location, photoUrl: e.target.value} })}
              value={this.state.location.photoUrl}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <TextField
              text="Web URL"
              id="webUrl"
              placeholder={"e.g. https://www.weareflorens.com"}
              onChange={(e) => this.setState({ location: {...this.state.location, webUrl: e.target.value} })}
              value={this.state.location.webUrl}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <TextField
              text="Instagram URL"
              id="instagramUrl"
              placeholder={"e.g. https://instagram.com/weareflorens"}
              onChange={(e) => this.setState({ location: {...this.state.location, instagramUrl: e.target.value} })}
              value={this.state.location.instagramUrl}
            />
          </div>
        </div>
      </div>
    )
  }

  fetchData(){
    const { locationId } = this.props.match.params
    api.getAdminEditLocation(locationId)
    .then(res => this.receiveInitialData(res.data))
    .catch(error => console.error("Error: ", error))
  }

  receiveInitialData(initialData) {
    const location = initialData
    console.log(initialData)
    this.setState({ location, isLoading: false })
  }

  handleFormSubmitted(){
    const { location } = this.state
    api.submitEditLocation(location)
    .then(res => {
      console.log(`${location.name} was edited sucessfully.`)
      this.setState({ formWasSuccessful: true })
    })
    .catch(error => console.error("Error: ", error))
  }

  handleDeletePressed(){
    const { location } = this.state
    api.submitDeleteLocation(location)
    .then(res => {
      this.setState({ formWasSuccessful: true })
    })
    .catch(error => console.error("Error: ", error))
  }
}

AdminEditLocation.propTypes = {}

export default withRouter(AdminEditLocation)
