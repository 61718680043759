import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AdminSubNav from 'components/organisms/AdminSubNav'
import AdminTopNav from 'components/organisms/AdminTopNav'
import moment from 'moment'
import api from 'utils/api'

const AdminLocationsList = (props) => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    api.admin.getLocations()
    .then(res => {
      console.log("Got response: ", res.data)
      setLocations(res.data.locations)
    })
    .catch(error => console.error("Error: ", error))
  }, [])

  return (
    <main>
      <AdminTopNav selected="locations" />

      <AdminSubNav>
        <Link to={`/admin/locations/new`} className="font-meta font-xs">Add Location</Link>
      </AdminSubNav>

      <div className="container-fluid pt-3">
        <div className="row" style={{ marginBottom: "4em" }}>
          <ul className="col-xs-12">

            <li className="AdminTableHeader">
              <div className="row middle-xs" style={{minHeight: "6rem"}}>
                <div className="col-xs-3">
                  <b>Name</b>
                </div>
                <div className="col-xs-2">
                  <b>Category</b>
                </div>
                <div className="col-xs-4">
                  <b>Address</b>
                </div>
                <div className="col-xs-2">
                  <b>Created At</b>
                </div>
                <div className="col-xs-1 end-md">
                  <b>Actions</b>
                </div>
              </div>
            </li>

            { locations.map(location =>
              <li
                className="AdminLocation"
                key={location.id}
              >
                <div className="row middle-xs" style={{minHeight: "6rem"}}>
                  <div className="col-xs-3">
                    <b>{location.name}</b>
                  </div>
                  <div className="col-xs-2">
                    <span className={`CategoryTag CategoryTag--${location.category.toLowerCase()}`}>
                      {location.category}
                    </span>
                  </div>
                  <div className="col-xs-4">
                    {location.streetAddress}<br/>
                    {location.city}, {location.state} {location.zip}<br />
                    [{location.latitude}, {location.longitude}]
                  </div>
                  <div className="col-xs-2">
                    {moment(location.createdAt).format("MM/DD/YY HH:mma")}
                  </div>
                  <div className="col-xs-1 end-md">
                    <Link to={`/admin/locations/${location.id}/edit`}>Edit</Link>
                  </div>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </main>
  )
}

AdminLocationsList.propTypes ={}

export default AdminLocationsList
