import React from 'react'
import PropTypes from 'prop-types'

const TextInput = (props) => {
  const classes = `TextInput ${props.isValid && "isValid"} ${props.classes}`
  return (
    <input
      autoFocus={props.autoFocus}
      type={props.type}
      className={classes}
      id={props.id}
      onBlur={props.onBlur}
      onChange={props.onChange}
      onFocus={props.onFocus}
      onKeyDown={(e) => { if (e.key === 'Enter'){ props.onEnterPressed() }}}
      placeholder={props.placeholder}
      value={props.value}
    />
  )
}

TextInput.propTypes = {
  classes: PropTypes.string,
  id: PropTypes.string,
  isValid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onEnterPressed: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired
}

export default TextInput
