import React from 'react'
import PropTypes from 'prop-types'

const LocationCard = (props) => {
  const { isSelected, location } = props

  return (
    <div className={`LocationCard mb-2 ${isSelected && "--isSelected"}`}>
      <div
        className="LocationCard__Photo"
        style={{ backgroundImage: `url(${location.photoUrl}` }}
      >
      </div>
      <div className="LocationCard__Content">
        <h3 className="font-copy font-md font-300 mb-1">{location.name}</h3>
        <p className="font-meta font-xs color-wt-sand mb-0">
          {location.streetAddress}<br />
          {location.city}, {location.state}
        </p>
      </div>
    </div>
  )
}

LocationCard.propTypes = {
  location: PropTypes.shape({
    name: PropTypes.string,
    streetAddress: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    photoUrl: PropTypes.string
  }).isRequired,
  isSelected: PropTypes.bool.isRequired
}

export default LocationCard
