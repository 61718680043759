import axios from 'axios'
import Routes from 'utils/routes'

const api = {
  admin: {
    getUsers(){
      const url = Routes.api.v1.admin.usersPath()
      const params = {}

      return axios.get(url, { params: params })
    },
    editUser(userId){
      const url = Routes.api.v1.admin.userEditPath(userId)
      const params = {}

      return axios.get(url, { params: params })
    },
    updateUser(user){
      const url = Routes.api.v1.admin.userUpdatePath(user.id)
      const data = {
        user: {
          email:               user.email,
          joined_waitlist_on:  user.joined_waitlist_on,
        }
      }
      return axios.post(url, { data: data })
    },
    getLocations() {
      const url = Routes.apiV1AdminLocationsPath()
      const params = {}

      return axios.get(url, { params: params })
    },
  },
  getLocations(query) {
    const url = Routes.apiV1LocationsPath()
    const params = {
      q: query
    }

    return axios.get(url, { params: params })
  },
  getAdminEditLocation(id) {
    const url = Routes.apiV1AdminEditLocationPath(id)
    const params = {}

    return axios.get(url, { params: params })
  },
  submitNewLocation(location) {
    const url = Routes.apiV1AdminLocationsPath()
    const data = {
      location: {
        name:           location.name,
        category:       location.category,
        street_address: location.streetAddress,
        city:           location.city,
        state:          location.state,
        zip:            location.zip,
        photo_url:      location.photoUrl,
        web_url:        location.webUrl,
        instagram_url:  location.instagramUrl
      }
    }
    return axios.post(url, {data: data})
  },
  submitEditLocation(location) {
    const url = Routes.apiV1AdminUpdateLocationPath(location.id)
    const data = {
      location: {
        id:             location.id,
        name:           location.name,
        category:       location.category,
        street_address: location.streetAddress,
        city:           location.city,
        state:          location.state,
        zip:            location.zip,
        latitude:       location.latitude,
        longitude:      location.longitude,
        photo_url:      location.photoUrl,
        web_url:        location.webUrl,
        instagram_url:  location.instagramUrl
      }
    }
    return axios.post(url, {data: data})
  },
  submitDeleteLocation(location) {
    const url = Routes.apiV1AdminDeleteLocationPath(location.id)
    const data = {}
    return axios.delete(url)
  },
  submitWaitlistEmail(email) {
    const url = Routes.apiV1SubmitWaitlistEmailPath()
    const data = {
      email: email
    }
    return axios.post(url, {data: data})
  }
}

export default api
